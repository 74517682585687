/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { graphql, useStaticQuery } from "gatsby";
import { FC } from "react";

export interface IFooter {}

export const Footer: FC<IFooter> = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      site {
        siteMetadata {
          copyright
          privacyPolicyUrl
        }
      }
    }
  `);
  const { copyright, privacyPolicyUrl } = data.site.siteMetadata;

  return (
    <footer id="footer">
      <div className="container footer-bottom clearfix">
        <div className="copyright">
          {copyright}
          <br></br>
          <br></br>
          <strong>Mailing Address</strong>
          <br></br>
          Wahlbecksgatan 25<br></br> 582 13 Linköping, Sweden
        </div>
        <div className="credits">
          <a
            css={css`
              text-decoration: none;
              color: #8fc04e;
              :hover {
                color: #7fac45;
              }
            `}
            href={privacyPolicyUrl}
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};
