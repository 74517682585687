import React, { FC } from "react";
import { FaDiscord, FaInstagram, FaTwitter } from "react-icons/fa";
import {
  DISCORD_LINK,
  INSTAGRAM_LINK,
  TWITTER_LINK,
} from "../../helpers/links";
import { NavOption } from "./NavOption";

export interface IMenuList {
  currentPage: PageType;
}

export type PageType = "/" | "/careers" | "/about" | "/contact";

export const MenuList: FC<IMenuList> = ({ currentPage }) => {
  return (
    <>
      <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
        <li>
          <NavOption name="Home" selected={currentPage === "/"} page="/" />
        </li>
        <li>
          <NavOption
            name="Careers"
            selected={currentPage === "/careers"}
            page="/careers"
          />
        </li>
        <li>
          <NavOption
            name="About"
            selected={currentPage === "/about"}
            page="/about"
          />
        </li>
        <li>
          <NavOption
            name="Contact"
            selected={currentPage === "/contact"}
            page="/contact"
          />
        </li>
      </ul>
      <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
        <li>
          <a
            href={TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
            className="NavBarButtonLink"
          >
            <FaTwitter style={{ fontSize: "35px" }} />
          </a>
        </li>
        {/*         <li>
          <a
            href={INSTAGRAM_LINK}
            target="_blank"
            rel="noreferrer"
            className="NavBarButtonLink"
          >
            <FaInstagram style={{ fontSize: "35px" }} />
          </a>
        </li> */}
        <li>
          <a
            href={DISCORD_LINK}
            target="_blank"
            rel="noreferrer"
            className="NavBarButtonLink wiggle-button"
          >
            <FaDiscord style={{ fontSize: "35px" }} />
          </a>
        </li>
      </ul>
    </>
  );
};
