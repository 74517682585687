import { Link } from "gatsby";
import React, { FC } from "react";
import { PageType } from "./MenuList";

export interface INavOption {
  selected: boolean;
  name: string;
  page: PageType;
}

export const NavOption: FC<INavOption> = ({ selected, name, page }) => {
  const style = "nav-link NavBarButtonLink" + (selected ? " active" : "");

  return (
    <Link className={style} to={page}>
      {name}
    </Link>
  );
};
