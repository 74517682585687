import { useOnClickOutside } from "../../helpers/useOnClickOutside";
import React, { FC, ReactNode, useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Logo } from "../Logo";
import { MenuList, PageType } from "../Navbar/MenuList";
import SEO from "../SEO";
import { Footer } from "../Footer";
import "../../main.scss";

export interface ILayout {
  children: ReactNode;
  currentPage?: PageType;
  pathname?: string;
  title?: string;
  description?: string;
  image?: string;
  isArticle?: boolean;
}

const Layout: FC<ILayout> = ({
  currentPage,
  children,
  pathname = "",
  title,
  description,
  image,
  isArticle,
}) => {
  const [showMobileBar, setShowMobileBar] = useState(false);
  const mobileBarRef = useRef(null);

  useOnClickOutside(mobileBarRef, () => setShowMobileBar(false));

  const menuList = <MenuList currentPage={currentPage} />;

  return (
    <div>
      <SEO
        pathname={pathname}
        title={title}
        description={description}
        image={image}
        isArticle={isArticle}
      />
      <header id="header" className="d-flex align-items-center fixed-top">
        <div
          ref={mobileBarRef}
          className="d-sm-flex d-lg-none mobile-navbar-container"
          style={{
            transform: showMobileBar ? "translateX(0)" : "translateX(100%)",
          }}
        >
          {menuList}
        </div>
        <div className="container d-flex justify-content-between">
          <Logo />
          <nav id="navbar" className="navbar">
            {menuList}
            <button
              className="ButtonLink bi mobile-nav-toggle bi-list"
              onClick={() => setShowMobileBar(!showMobileBar)}
              style={{ padding: 0, margin: 0, color: "white" }}
            >
              {showMobileBar ? <FaTimes /> : <FaBars />}
            </button>
          </nav>
        </div>
      </header>
      <div className="scrolled-offset">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
