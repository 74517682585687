import { Link } from "gatsby";
import React, { FC } from "react";

export const Logo: FC = () => {
  return (
    <div className="logo">
      <h1 className="text-light">
        <Link to="/">
          <img
            src="beartwigs_name_long_white_pathed.svg"
            width="200px"
            alt=""
          />
        </Link>
      </h1>
    </div>
  );
};
